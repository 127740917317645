import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import {
  ACL,
  APP,
  FIRST_VISIBLE_ROUTES,
  PAGE_NAMES,
  EVENT_LANDING_REDIRECTION,
  ENABLED_FEATURES,
} from 'appenv';
import Apps from 'apps';
import { EventDatetimeContext } from 'components/EventDatetime';
import moment from 'moment';
import { BookmarksContext } from 'components/modals/bookmarks/Bookmarks';
import AuthenticatingIndicator from './AuthenticatingIndicator';
import attendeeByUniqueToken from '../network/attendeeByUniqueToken';

function fetchUserSession() {
  try {
    return JSON.parse(localStorage.getItem('userSession'));
  } catch (e) {
    return {};
  }
}

function saveUserSession(userSession) {
  window.trackingUser(userSession);
  localStorage.setItem('userSession', JSON.stringify(userSession));
}

const UserSessionPersistentStore = {
  fetch: fetchUserSession,
  save: saveUserSession,
};

const UserSessionContext = React.createContext({
  userSession: {},
  setUserSession() {},
  canSee: () => {},
  firstVisibleRoute: null,
  isAuthenticating: false,
  isAuthenticated: false,
});

function UserSessionContextStateProvider({ children }) {
  const [userSessionState, setUserSessionState] = useState(UserSessionPersistentStore.fetch());
  const [authToken, setAuthToken] = useState(queryString.parse(document.location.search).auth_token || queryString.parse(document.location.search)['auth§token']);
  const { hasEventStarted, hasWebinarGoneLive, hasEventEnded } = useContext(EventDatetimeContext);
  const { loadDefaultBookmarks } = useContext(BookmarksContext);
  const isInit = useRef(false);

  const isAuthenticating = authToken && authToken !== '';
  const isAuthenticated = !isEmpty(userSessionState);

  function canSee(item) {
    if (!isInit.current) return true;

    if (item === 'contacts') {
      return APP === Apps.VirtualDemo;
    }

    if (item === 'boothPreview') {
      return ENABLED_FEATURES.boothPreview;
    }

    if (hasEventEnded && EVENT_LANDING_REDIRECTION.eventEndedRedirectToLanding) {
      localStorage.clear();
      return ACL({}, item);
    }

    if (item === 'webinar' && !hasWebinarGoneLive) {
      // User can't see webinar page unless it goes live
      return false;
    }

    if (item === 'participant') {
      return ACL(userSessionState || {}, item) && Object.keys(PAGE_NAMES).includes(item);
    }

    if (item === 'products' && !ENABLED_FEATURES.productPage) {
      return false;
    }

    if (item === 'publicChatroom' && !ENABLED_FEATURES.publicChatroom) {
      return false;
    }

    return ACL(userSessionState || {}, item);
  }

  const getUserRole = (tag, vexpoOrganizer) => {
    if (vexpoOrganizer) return 'organizer';
    switch (tag?.toLowerCase()) {
      case 'organizer':
      case 'exhibitor':
        return tag?.toLowerCase();
      default:
        return 'attendee';
    }
  };

  async function importFromQueryString() {
    if (authToken) {
      let userSession = {};
      try {
        const attendeeData = await attendeeByUniqueToken(authToken);
        if (attendeeData) {
          const {
            customAttendeeFields: {
              vexpo_chat_tag: tag,
              vexpo_organizer: vexpoOrganizer,
            },
          } = attendeeData;
          userSession = {
            authToken,
            role: getUserRole(tag, vexpoOrganizer),
            ...attendeeData,
            fullName: `${attendeeData.firstName} ${attendeeData.lastName}`,
            lastReceivedAt: moment(),
          };
        }
      } catch (e) {
        console.error(e);
      }
      UserSessionPersistentStore.save(userSession);
    }
  }

  async function refreshUserSession() {
    let userSession = UserSessionPersistentStore.fetch() || {};
    if (!userSession.authToken) return;
    const needRefresh = !userSession.lastReceivedAt || moment(userSession.lastReceivedAt).diff(moment(), 'hours') >= 1;
    if (!needRefresh) return;
    try {
      const attendeeData = await attendeeByUniqueToken(userSession.authToken);
      if (attendeeData) {
        userSession = {
          authToken: userSession.authToken,
          ...attendeeData,
          fullName: `${attendeeData.firstName} ${attendeeData.lastName}`,
          lastReceivedAt: moment(),
        };
      } else {
        userSession = {};
      }
    } catch (e) {
      console.error(e);
    }
    UserSessionPersistentStore.save(userSession);
  }

  function loadFromPersistentStore() {
    const userSession = UserSessionPersistentStore.fetch();
    setUserSessionState(userSession);
    setAuthToken(null);
  }

  async function initialize() {
    await importFromQueryString();
    await refreshUserSession();
    loadFromPersistentStore();
  }

  useEffect(() => {
    initialize();
    isInit.current = !isInit.current;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function setUserSession(newUserSession) {
    UserSessionPersistentStore.save(newUserSession);
    loadFromPersistentStore();
  }

  const firstVisibleRoute = FIRST_VISIBLE_ROUTES.find((route) => canSee(route));

  if (isAuthenticating) {
    loadDefaultBookmarks();
    return <AuthenticatingIndicator />;
  }

  return (
    <UserSessionContext.Provider
      value={{
        userSession: userSessionState,
        setUserSession,
        canSee,
        isAuthenticating,
        isAuthenticated,
        firstVisibleRoute,
      }}
    >
      {children}
    </UserSessionContext.Provider>
  );
}

UserSessionContextStateProvider.propTypes = {
  children: PropTypes.node,
};

UserSessionContextStateProvider.defaultProps = {
  children: null,
};

export {
  UserSessionContext,
  UserSessionContextStateProvider,
  UserSessionPersistentStore,
};
