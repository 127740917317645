import { createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { selectLocale } from 'models/localization';
import unionBy from 'lodash/unionBy';
import { Booth } from './types';
import { selectBoothTaxonomies } from '../events';

export const boothsAdapter = createEntityAdapter<Booth>();

export const {
  selectIds: selectBoothIds,
  selectEntities: selectBoothEntities,
  selectAll: selectAllBooths,
  selectById: selectBoothById,
} = boothsAdapter.getSelectors((state: any) => state.cms.booths);

export const selectBoothPageInfo = createSelector(
  (state: any) => state.cms.booths,
  (booths) => booths.pageInfo,
);

export const selectAllValidBooths = createSelector(
  selectAllBooths,
  (booths) => booths.filter((it) => Object.values(it.name).some((name) => name)),
);

export const selectFetchingStateByBoothId = (boothId: string) => createSelector(
  (state: any) => state.cms.booths,
  (booths) => booths.boothFetchingState[boothId],
);

export const selectBoothsByKeyword = (keyword: string, options: any = {}) => {
  const { locale } = options;
  const limit = options.limit || undefined;
  const lowerCaseKeyword = keyword.toLowerCase();
  return createSelector(
    [selectAllValidBooths, selectLocale],
    (booths, fallbackLocale) => (
      booths
        .filter((booth) => (
          lowerCaseKeyword && (
            (booth.name[locale] || booth.name[fallbackLocale])?.toLowerCase().includes(lowerCaseKeyword)
            || booth.categories.some((category) => (category.name[locale] || category.name[fallbackLocale])?.toLowerCase().includes(lowerCaseKeyword))
          )
        ))
        .slice(0, limit)
    ),
  );
};

export const filterBoothsByKeywords = (keyword: any) => {
  if (Object.keys(keyword).length === 0) return selectAllValidBooths;
  return createSelector(
    selectAllValidBooths,
    (booths) => {
      const options = Object.keys(keyword);
      return booths.filter((booth) => {
        const names = booth.categories
          .flatMap((category) => Object.values(category.name));
        return options.every((it) => names.includes(it));
      });
    },
  );
};

const getFilterOptionByBooth = createSelector(
  [selectAllValidBooths],
  (booths) => unionBy(booths.flatMap((it) => it.categories), (booth) => booth.id),
);

export const getFilterOptions = () => createSelector(
  [selectBoothTaxonomies, getFilterOptionByBooth],
  (boothTaxonomies, options) => (boothTaxonomies.length ? boothTaxonomies : options),
);

export const filterBoothsByFilterOptions = (selectedFilter: string[]) => {
  if (!(selectedFilter?.length > 0)) return selectAllValidBooths;
  return createSelector(
    [selectAllValidBooths, getFilterOptions()],
    (booths, filterOptions) => {
      const conditions = filterOptions
        .map((it) => (
          Array.isArray(it.subcategories) ? it.subcategories : [it])
          .filter((sub) => selectedFilter.includes(sub.id)))
        .filter((it) => it.length !== 0);

      return booths
        .filter((booth) => {
          const categoriesId = (booth.categories || []).map((it) => it.id);
          return conditions
            .every((condition) => condition.some((it) => categoriesId.includes(it.id)));
        });
    },
  );
};
