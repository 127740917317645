import React, { Suspense } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { envSwitch } from 'utils/envUtils';
import BannerView from 'pages/main_lobby/BannerTemplateSwitch';
import { ENABLED_FEATURES, EVENT_BANNERS } from 'appenv';
import { useSelector } from 'react-redux';
import { BannerPosition, selectBannersByPosition } from 'models/banners';
import Apps from 'apps';
import LobbyFooterSwitch from '../LobbyFooterSwitch';

const RootContainer = styled.div`
  height: 100%;
  overflow-y: ${({ overflow }) => overflow};
  overflow-x: hidden;
  background: #DADBDE;
`;

const BackgroundVideo = styled.video`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
`;

const ContentContainerWrapper = styled.div`
  flex-shrink: 0;
  width: 100%;
  display: flex;
  align-items: ${({ alignItems }) => alignItems};
  max-height: 100%;
  max-width: 100%;
`;

const ContentContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  bottom: 0;
`;

const BannerLink = styled.a`
  position: absolute;
  left: ${({ left }) => left}%;
  right: ${({ right }) => right}%;
  top: ${({ top }) => top}%;
  width: ${({ width }) => width}%;
  height: ${({ height }) => height}%;
  transform: ${({ skewX, skewY }) => `skewX(${skewX}deg) skewY(${skewY}deg)`};
  display: block;
  text-decoration: none;
`;

const BannerRouteLink = styled(Link)`
  position: absolute;
  left: ${({ left }) => left}%;
  top: ${({ top }) => top}%;
  width: ${({ width }) => width}%;
  height: ${({ height }) => height}%;
  transform: ${({ skewX, skewY }) => `skewX(${skewX ?? 0}deg) skewY(${skewY ?? 0}deg)`} translate(-50%,-50%);
  display: block;
  text-decoration: none;

  & > img {
    width: 100%;
    height: 100%;
  }
`;

const InnerVideo = styled.video`
  position: absolute;
  left: ${({ left }) => left}%;
  top: ${({ top }) => top}%;
  width: auto;
  height: ${({ height }) => height}%;
  transform: translateX(-50%);
`;

const MiddleBanner = styled(BannerView)`
  position: absolute;
  left: ${({ left }) => left}%;
  top: ${({ top }) => top}%;
  width: ${({ width }) => width}%;
  height: ${({ height }) => height}%;
  flex: 1 0 auto;
  transform: translateX(-50%);
`;

const StyledBackgroundImage = styled.img`
  width: 100%;
`;

const FooterWrapper = styled.div`
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: 0;
`;

const DesktopFancyLobbyViewTemplate = () => {
  const backgroundVideoSrc = envSwitch([
    [Apps.PicoX707, 'https://contents-virtual.eventxtra.com/uploads/vxevents_staging/originals/eba43b18-9d7d-494a-9aa4-0f4039b2339a.mp4'],
    [Apps.VirtualDemo, 'https://contents-virtual.eventxtra.com/uploads/virtualdemo_staging/originals/d5aeb017-d9cf-48a1-8158-8b96cf55423a.mp4'],
    [Apps.Sph818, 'https://contents-virtual.eventxtra.com/uploads/mig818_staging/originals/ce1a52f4-3286-444c-aed4-8954fc3082f3.mp4'],
    [Apps.Mig0828, 'https://contents-virtual.eventxtra.com/uploads/mig828_staging/originals/2a98dfa6-d108-4711-8f7c-c38945421ac1.mp4'],
    [Apps.Emsd831, 'http://contents-virtual.eventxtra.com/uploads/emsd831_production/originals/6424f86f-c452-4a12-99d2-55be6be67f7d.mp4'],
    [[Apps.VirtualPressCon], 'https://contents-virtual.eventxtra.com/uploads/xtra_staging/originals/d5aeb017-d9cf-48a1-8158-8b96cf55423a.mp4'],
    [Apps.SPH706, 'https://contents-virtual.eventxtra.com/uploads/sphdemo_staging/originals/d9f1aee4-fdd9-4435-9c85-bfa46e362f66.mp4'],
    [Apps.Jetro817, 'https://contents-virtual.eventxtra.com/uploads/jetro817_staging/originals/c0721bec-7346-462d-92be-78f96ad0dcaf.mp4'],
    [Apps.Uzabase826, 'https://contents-virtual.eventxtra.com/uploads/uzabase826_production/originals/daf16e88-fe97-4917-aaa9-569f48f4e062.mp4'],
    [Apps.Eventage824, 'https://contents-virtual.eventxtra.com/uploads/eventage_production/originals/5a8f9771-682e-4d90-8629-366077d115f5.mp4'],
  ], null);

  const backgroundPreviewSrc = envSwitch([
    [Apps.PicoX707, 'https://contents-virtual.eventxtra.com/uploads/vxevents_staging/originals/c84fe0a6-a25d-4afb-adc3-f4abe5b0a3bb.jpeg'],
    [[Apps.VirtualDemo, Apps.Sph818], 'https://contents-virtual.eventxtra.com/uploads/virtualdemo_staging/originals/a9b6e1ed-3371-43bb-ace1-6159d58e55f9.jpeg'],
    [[Apps.VirtualPressCon], 'https://contents-virtual.eventxtra.com/uploads/xtra_staging/originals/a9b6e1ed-3371-43bb-ace1-6159d58e55f9.jpeg'],
    [Apps.Emsd831, 'https://contents-virtual.eventxtra.com/uploads/virtualdemo_staging/originals/a9b6e1ed-3371-43bb-ace1-6159d58e55f9.jpeg'],
    [Apps.SPH706, 'https://contents-virtual.eventxtra.com/uploads/sphdemo_staging/originals/6e8443a5-841c-4d22-b8cc-bc30553547e3.jpg'],
    [Apps.Jetro817, 'https://contents-virtual.eventxtra.com/uploads/jetro817_staging/originals/47146649-e151-4056-80a0-84514178da57.jpg'],
    [Apps.Uzabase826, 'https://contents-virtual.eventxtra.com/uploads/vexpo/originals/f486f118-07df-4799-8b93-5c06f1d6eba3.jpg'],
    [[Apps.Mig0828], 'https://contents-virtual.eventxtra.com/uploads/virtualdemo_staging/originals/1b4a96ff-2c8e-497f-b241-f4e4404e2275.png'],
    [Apps.Eventage824, 'https://contents-virtual.eventxtra.com/uploads/eventage_staging/originals/558b663b-1be8-45d2-89db-749a18dfac86.png'],
  ], null);

  const clickableLinks = envSwitch([
    ['picox707', [
      <BannerRouteLink to="hall" width="20" height="40" left="27" top="35" />,
      <BannerRouteLink to="webinar" width="20" height="40" left="73" top="35" />,
    ]],
    [[Apps.VirtualDemo, Apps.VirtualPressCon, Apps.Sph818, Apps.Uzabase826], [
      <BannerLink
        target="_blank"
        href="https://eventxtra.com/virtual-exhibition-v/?organiser=EventXtra&domain=eventxtra.com&utm_source=eventxtra&utm_medium=internal_referral&utm_campaign=virtual_page_banner#"
        width="15.5"
        height="18.5"
        left="2"
        top="6.5"
      />,
      <BannerRouteLink to="hall" width="13" height="14" left="72.5" top="14.5" skewX="0" skewY="-7.5" />,
      <BannerRouteLink to="hall" width="16" height="18" left="58" top="42" />,
      <BannerRouteLink to="webinar" width="22" height="26" left="88" top="62" />,
    ]],
    [[Apps.Sph818], [
      <BannerLink
        target="_blank"
        href="/booth/142"
        width="15.5"
        height="18.5"
        right="20"
        top="6.5"
      />,
      <BannerRouteLink to="hall" width="28.5" height="29.2" left="50.5" top="18.4" />,
      <BannerRouteLink to="hall" width="16" height="18" left="58" top="42" />,
      <BannerRouteLink to="webinar" width="22" height="26" left="88" top="60" />,
    ]],
    [[Apps.SPH706, Apps.Sph818], [
      <BannerRouteLink to="hall" width="28.5" height="29.2" left="50.5" top="18.4" />,
      <BannerRouteLink to="hall" width="16" height="18" left="58" top="42" />,
      <BannerRouteLink to="webinar" width="22" height="26" left="88" top="60" />,
    ]],
    [Apps.Mig0828, [
      <BannerRouteLink to="hall" width="16" height="18" left="58" top="42" />,
      <BannerRouteLink to="webinar" width="22" height="26" left="88" top="60" />,
    ]],
    [Apps.Emsd831, [
      <BannerLink
        target="_blank"
        href="https://inno.emsd.gov.hk/tc/home/"
        width="15.5"
        height="16.5"
        left="1.5"
        top="10"
      />,
      <BannerLink
        target="_blank"
        href="https://livetour.istaging.com/e7d6791b-d706-427b-b238-6c6cb9c15d66"
        width="15.5"
        height="16.5"
        left="19"
        top="10"
      />,
      <BannerRouteLink to="hall" width="16" height="18" left="58" top="42" />,
      <BannerRouteLink to="webinar" width="22" height="26" left="88" top="60" />,
    ]],
    [Apps.Jetro817, [
      <BannerRouteLink key="tv" to="hall" width="28.5" height="29.2" left="50.5" top="18.4" />,
      <BannerRouteLink key="hall" to="hall" width="16" height="18" left="58" top="42" />,
    ]],
    [Apps.Eventage824, [
      <BannerRouteLink key="tv" to="hall" width="28.5" height="29.2" left="56" top="40" />,
      <BannerRouteLink key="hall" to="webinar" width="16" height="18" left="88" top="52" />,
    ]],
  ], []);

  const alignment = envSwitch([], 'flex-start');

  const overflow = envSwitch([
    [[Apps.VirtualDemo, Apps.VirtualPressCon, Apps.SPH706, Apps.Jetro817, Apps.Mig0828, Apps.Sph818, Apps.Emsd831, Apps.Eventage824], 'auto'],
  ], 'hidden');

  const autoPlay = envSwitch([],
    {
      left: true,
      middle: true,
      right: true,
    });

  const middleBanners = useSelector(selectBannersByPosition(BannerPosition.middle));
  const middleVideo = envSwitch([
    [[Apps.VirtualDemo], 'https://contents-virtual.eventxtra.com/uploads/xtra_staging/originals/7b8eb06b-dd05-4698-ac1d-f9fefa3360ba.mp4'],
    [[Apps.VirtualPressCon], 'https://contents-virtual.eventxtra.com/uploads/xtra_staging/originals/7b8eb06b-dd05-4698-ac1d-f9fefa3360ba.mp4'],
    [Apps.Mig0828, 'https://contents-virtual.eventxtra.com/uploads/mig828_staging/originals/1e374b97-e78e-4728-9e55-a9541c859e40.mp4'],
    [[Apps.Emsd831], 'https://contents-virtual.eventxtra.com/uploads/emsd831_production/originals/572e1a08-bda0-4235-976d-8878410061e9.mp4'],
  ]);
  const muted = envSwitch([
    [Apps.Mig0828, false],
    [Apps.Emsd831, false],
  ], true);
  const middleBannerPosition = envSwitch([
    [[Apps.VirtualDemo, Apps.VirtualPressCon, Apps.Sph818, Apps.Emsd831, Apps.Mig0828], { top: 5 }],
  ], {});

  return (
    <RootContainer overflow={overflow}>
      <ContentContainerWrapper alignItems={alignment}>
        <ContentContainer>
          {backgroundPreviewSrc && <StyledBackgroundImage src={backgroundPreviewSrc} />}
          {
            backgroundVideoSrc && (
              <BackgroundVideo autoPlay loop muted playsinline>
                <source src={backgroundVideoSrc} type="video/mp4" />
              </BackgroundVideo>
            )
          }
          {((middleBanners && middleBanners.length > 0) || (EVENT_BANNERS?.middle?.length ?? 0) > 0) && (
            <MiddleBanner
              height={20}
              left={50}
              top={middleBannerPosition.top ?? 20}
              width={30}
              assets={ENABLED_FEATURES.cmsBanner && middleBanners?.length > 0 ? middleBanners : EVENT_BANNERS.middle}
              autoPlay={autoPlay.middle}
            />
          )}
          {middleVideo && (
            <InnerVideo
              height={27}
              left={50.3}
              top={middleBannerPosition.top ?? 20}
              autoPlay
              controls
              muted={muted}
              playsinline
            >
              <source src={middleVideo} type="video/mp4" />
            </InnerVideo>
          )}
          {clickableLinks}
        </ContentContainer>
      </ContentContainerWrapper>
      <FooterWrapper>
        <Suspense fallback={null}>
          <LobbyFooterSwitch />
        </Suspense>
      </FooterWrapper>
    </RootContainer>
  );
};

export default DesktopFancyLobbyViewTemplate;
