import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import rootReducer from 'models';
import { throttle } from 'lodash';
import { APP_ENV } from 'appenv';
import loadState from 'state/loadState';
import saveState from 'state/saveState';

const store = configureStore({
  reducer: rootReducer,
  preloadedState: {
    productCategories: {
      selected: loadState(`${APP_ENV}_selectedProductCategoryFilter`),
    },
    bookmarks: loadState(`${APP_ENV}_selectedBookmarks`),
  },
});

store.subscribe(throttle(() => {
  saveState(`${APP_ENV}_selectedProductCategoryFilter`, store.getState().productCategories.selected);
}));

store.subscribe(throttle(() => {
  saveState(`${APP_ENV}_selectedBookmarks`, store.getState().bookmarks);
}, 3000));

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
