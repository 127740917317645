import { request, gql } from 'graphql-request';
import { CommonLocalizableString } from './common/cmsFragment';
import { apiUrl } from './common/cmsConfig';

const fetchCMSBoothNameQuery = gql`
  ${CommonLocalizableString}

  query($boothId: ID!, $locales: [Locale!]!) {
    node(id: $boothId) {
      ... on Booth {
        name {
          ...CommonLocalizableString
        }
      }
    }
  }
`;

const fetchCMSBoothName = (boothId: string, locales = ['en']) => request(
  apiUrl,
  fetchCMSBoothNameQuery,
  { boothId, locales },
).then((result) => result.node);

export default fetchCMSBoothName;
