import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Routes from 'Routes';
import ReactHtmlParser from 'react-html-parser';
import { APP, ENABLED_FEATURES } from 'appenv';
import { useSelector, useDispatch } from 'react-redux';
import { selectLocale } from 'models/localization';
import { selectFetchingStateByBoothId, BoothFetchingState, fetchBoothById } from 'models/cms/booths';
import CircularLoadingIndicator from 'components/indicators/CircularLoadingIndicator';
import remoteTimeNow from 'libs/remote-time-now';
import moment from 'moment';

import FeaturedStar from './FeaturedStar';
import BoothBackground from '../booths/BoothTemplateSwitch';

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #484848;
`;

const UPGRADED_BOOTH_SCALE_MULTIPLIER = 1.2;

const BoothScaler = styled.div`
  width: 378px;
  height: 310px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (min-width: 960px) {
    width: ${({ upgraded }) => 378 * (upgraded ? UPGRADED_BOOTH_SCALE_MULTIPLIER : 1)}px;
    height: ${({ upgraded }) => 310 * (upgraded ? UPGRADED_BOOTH_SCALE_MULTIPLIER : 1)}px;
  }
`;

const BoothContainer = styled.div`
  width: 330px;
  min-height: 270px;
  max-height: 300px;
  text-align: center;
  padding: 16px;
  box-sizing: border-box;
  background-color: rgba(256, 256, 256, 0.8);
  transition: background-color 0.15s;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin: 20px 24px;
  position: relative;

  &:hover {
    background-color: rgba(256, 256, 256, 1);
  }

  @media (min-width: 960px) {
    transform: scale(${({ upgraded }) => (upgraded ? UPGRADED_BOOTH_SCALE_MULTIPLIER : 1)});
  }
`;

const StyledTooltip = withStyles(() => ({
  tooltip: {
    maxWidth: '330px',
  },
}))(Tooltip);

const BoothCompanyName = styled.div`
  width: 100%;
  min-height: 30px;
  text-align: center;
  font-weight: bold;
  line-height: 29px;
  color: #484848;
  margin-bottom: 16px;
  padding: 0 24px;

  @media (min-width: 960px) {
    white-space: nowrap;
    overflow: hidden;
    padding: initial;
    text-overflow: ellipsis;
  }
`;

const BoothImageDescriptionContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const BoothBackgroundImageContainer = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 16px;
  position: absolute;
  top: -223px;
  left: -77px;
  z-index: 0;
  transition: all 0.3s;
  transform: scale(0.25);
  opacity: ${({ isHovering }) => (isHovering ? 0 : 1)};
`;

const BoothDescription = styled.div`
  position: relative;
  width: 100%;
  height: 190px;
  text-align: justify;
  word-break: break-word;
  transition: all 0.3s;
  opacity: ${({ isHovering }) => (isHovering ? 1 : 0)};
  overflow: hidden;
  z-index: 1000;

  & > * {
    margin: 0;
    padding: 0;
  }
`;


const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image:
    linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 1)
    );
`;

const mapDefaultTargetBooth = (booth, locale) => {
  const translation = booth.translations[locale] || {};
  const isFundingKind = booth ? (booth.tags.find((tag) => tag.includes('Stage of Funding: ')) || false) : false;
  const logoThumbs = booth.logo?.data?.thumbnails;
  const bestLogoThumb = logoThumbs?.find((thumb) => thumb?.url?.endsWith('?key=directus-medium-contain'));
  const bannerThumbs = translation.vertical_banner?.data?.thumbnails;
  const bestBannerThumb = bannerThumbs?.find((thumb) => thumb?.url?.endsWith('?key=directus-medium-contain'));
  // eslint-disable-next-line camelcase
  const bannerFileUrl = translation.vertical_banner_file?.data?.full_url;
  const bannerFileType = translation.vertical_banner_file?.type;
  // eslint-disable-next-line camelcase
  const verticalBannerThumbs = translation.vertical_banner?.data?.thumbnails;
  const bestVerticalBannerThumb = verticalBannerThumbs?.find((thumb) => thumb?.url?.endsWith('?key=directus-medium-contain'));

  const verticalBanner = translation.vertical_banner_detail;
  const descriptionBanner = translation.description_detail;
  const logoBanner = translation.logo_detail;

  const targetBooth = {
    id: booth.id,
    name: translation.name,
    upgraded: booth?.type === 'u',
    type: booth?.type,
    logo: bestLogoThumb?.url,
    verticalBanner: bestBannerThumb?.url,
    verticalBannerFullImg: bestVerticalBannerThumb?.url,
    verticalBannerFile: bannerFileUrl,
    verticalBannerFileType: bannerFileType,
    description: translation.description,
    boothNumber: booth.booth_number,
    design: booth.booth_design,
    industry: booth.industry,
    country: booth.country,
    website: booth.website,
    boothColor: booth.booth_color,
    // eslint-disable-next-line camelcase
    boothColorRight: booth?.booth_color_right,
    attachments: booth?.attachments || booth?.files || [],
    socialMedias: booth.socialMedias || [],
    emailContacts: booth.emailContacts || [],
    phoneContacts: booth.phoneContacts || [],
    extraFields: translation.extra_fields || {},
    isFundingKind,
    banner: {
      verticalBanner,
      descriptionBanner,
      logoBanner,
    },
    banners: {
      left: booth.left_banner,
      top: booth.top_banner,
      center: booth.center_banner,
      right: booth.right_banner,
      bottom: booth.bottom_banner,
    },
  };

  return targetBooth;
};

const pickFieldFromDefaultTargetBooth = (booth, field) => {
  switch (field) {
    case 'descriptionBanner': return booth.banner.descriptionBanner;
    default: return booth[field];
  }
};

const pickFieldFromCMSBoothTargetBooth = (booth, field, locale) => {
  switch (field) {
    case 'name': return booth.name[locale];
    case 'description': {
      const description = booth?.description?.[locale] || '';
      const content = new DOMParser().parseFromString(description, 'text/html')?.body?.textContent?.trim();
      if (!content) return '';
      return description;
    }
    case 'descriptionBanner': return null;
    default: return booth[field];
  }
};

const mapTargetBooth = ENABLED_FEATURES.xtraCMS
  ? (booth) => booth
  : mapDefaultTargetBooth;

const pickFieldFromTargetBooth = ENABLED_FEATURES.xtraCMS
  ? pickFieldFromCMSBoothTargetBooth
  : pickFieldFromDefaultTargetBooth;

const selectFetching = ENABLED_FEATURES.xtraCMS
  ? (boothId, lastReceivedAt) => (state) => {
    const fetchingState = selectFetchingStateByBoothId(boothId)(state);
    return remoteTimeNow().diff(moment(lastReceivedAt), 'minutes') > 4.5
      || fetchingState === BoothFetchingState.Pending;
  }
  : () => () => false;

const BoothPreview = ({ booth }) => {
  const dispatch = useDispatch();
  const [isHovering, setIsHovering] = useState(false);
  const locale = useSelector(selectLocale);
  const isFetching = useSelector(selectFetching(booth.id, booth.$lastReceivedAt));

  const targetBooth = mapTargetBooth(booth, locale);
  const boothName = pickFieldFromTargetBooth(targetBooth, 'name', locale);
  const boothDescription = pickFieldFromTargetBooth(targetBooth, 'description', locale);
  const boothDescriptionBanner = pickFieldFromTargetBooth(targetBooth, 'descriptionBanner', locale);

  const boothType = booth?.type;
  const isUpgradedBooth = useMemo(() => boothType === 'u', [boothType]);
  const hideDescription = useMemo(() => ['ms624'].includes(APP) || !boothDescription, [boothDescription]);

  useEffect(() => {
    if (!ENABLED_FEATURES.xtraCMS || !booth.id) return;
    if (remoteTimeNow().diff(moment(booth.$lastReceivedAt), 'minutes') <= 4.5) return;
    dispatch(fetchBoothById(booth.id));
  }, [booth.id, booth.$lastReceivedAt, dispatch]);

  return (
    <BoothScaler upgraded={isUpgradedBooth}>
      <BoothContainer
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        isHovering={isHovering && !hideDescription}
        upgraded={isUpgradedBooth}
      >
        <StyledLink to={Routes.booth(booth.id)}>
          <StyledTooltip title={boothName} placement="top">
            <BoothCompanyName>{boothName}</BoothCompanyName>
          </StyledTooltip>
          {isFetching && <CircularLoadingIndicator />}
          {!isFetching && (
            <BoothImageDescriptionContainer>
              <BoothDescription isHovering={isHovering && !hideDescription} descriptionBanner={boothDescriptionBanner}>
                {ReactHtmlParser(boothDescription)}
                <GradientOverlay />
              </BoothDescription>
              <BoothBackgroundImageContainer isHovering={isHovering && !hideDescription}>
                <BoothBackground booth={targetBooth} showRepsImg={false} isPreview />
              </BoothBackgroundImageContainer>
            </BoothImageDescriptionContainer>
          )}
        </StyledLink>
        {isUpgradedBooth && (<FeaturedStar />)}
      </BoothContainer>
    </BoothScaler>
  );
};

BoothPreview.propTypes = {
  booth: PropTypes.shape().isRequired,
};

export default BoothPreview;
